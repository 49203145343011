import { useAppContext } from "context/app.context";
import { useCallback, useState } from "react";
import { loginWithUsernameAndPassword } from "services/auth";
import { LOGIN_RESPONSE_STATUS } from "./login.consts";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "models/auth.types";
import { useAuth } from "hooks/useAuth";

export const useHandleLoginWithCredentials = (
  toggleIsLoading: () => void,
  redirectUrlOnLogin: string | null
) => {
  const { saveUserDetails } = useAuth();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const handlePassword = useCallback(
    (e) => {
      const passwordValue = e.target.value;
      setPassword(passwordValue);
    },
    [setPassword]
  );

  const handleUsername = useCallback(
    (e) => {
      const usernameValue = e.target.value;
      setUsername(usernameValue);
    },
    [setUsername]
  );

  const { dispatch } = useAppContext();
  const navigation = useNavigate();

  const handleLogin = useCallback(async () => {
    toggleIsLoading();
    const loginTime = new Date().toLocaleTimeString();
    try {
      const loginResult = (await loginWithUsernameAndPassword(
        username,
        password
      )) as LoginResponse;

      if (loginResult.status === LOGIN_RESPONSE_STATUS.VALID) {
        const { data, refreshToken, accessToken, expiresIn } = loginResult;
        const userAuthData = {
          userId: data.userId,
          name: data.name,
          userType: data.userType,
          loginTime,
          expiry: data.accountExpiry,
          googleId: data.googleId,
        };
        const tokenData = {
          refreshToken,
          accessToken,
          expiresIn,
        };
        saveUserDetails(userAuthData, tokenData);
        dispatch({
          type: "LOGIN",
          payload: {
            userId: data.userId,
            userType: data.userType,
            userAuthData,
          },
        });
        if (redirectUrlOnLogin) {
          window.location.href = redirectUrlOnLogin;
        } else {
          navigation("/home");
        }
        toast.success("Login Successful");
      } else if (status === LOGIN_RESPONSE_STATUS.INVALID) {
        toast.error("Invalid Username or Password. Please try again.");
      } else {
        toast.error("Error with logging in.");
      }
      toggleIsLoading();
    } catch {
      console.error("error with logging in");
      toggleIsLoading();
    }
  }, [
    password,
    username,
    toggleIsLoading,
    navigation,
    dispatch,
    saveUserDetails,
    redirectUrlOnLogin,
  ]);

  return { handleLogin, handlePassword, handleUsername };
};
