import { useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addNewGoogleAccount } from "services/auth";
import { useAppContext } from "context/app.context";

export const useHandleGoogleConnectResponse = (toggleIsLoading: () => void) => {
  const navigation = useNavigate();
  const { dispatch } = useAppContext();

  const handleGoogleError = useCallback(() => {
    toast.error("Error with connecting with Google.");
  }, []);
  const {
    state: { userId },
  } = useAppContext();
  const handleGoogleSuccess = useCallback(
    async (res) => {
      toggleIsLoading();

      try {
        const connectResult = await addNewGoogleAccount(userId, res.code);
        // @ts-ignore
        if (connectResult.message) {
          // @ts-ignore
          toast.error(connectResult.message);
        } else {
          const {
            // @ts-ignore
            data: { googleId },
          } = connectResult;

          if (googleId) {
            dispatch({
              type: "GOOGLE_ACCOUNT_ADDED",
              payload: {
                googleId,
              },
            });
            navigation("/home");
          }
        }
      } catch (error) {
        console.error("Google login error:", error);
        toast.error("Error with logging with Google.");
      }
      toggleIsLoading();
    },
    [navigation, userId, dispatch]
  );
  return { handleGoogleSuccess, handleGoogleError };
};
