import { postData } from "utils/networking/postData";

export const loginWithUsernameAndPassword = async (
  username: string,
  password: string
) => {
  const LOGIN_URL = `authentication`;
  const result = await postData({
    uri: LOGIN_URL,
    body: { user: username, password },
    convertToCamel: true,
  });
  return result;
};

export const addNewGoogleAccount = async (userId: string, code: string) => {
  const ADD_ACCOUNT_URL = "authentication/google-account-add";
  const tokenResult = await postData({
    uri: ADD_ACCOUNT_URL,
    body: {
      userId,
      code,
    },
    convertToCamel: true,
  });
  return tokenResult;
};

export const googleCodeToToken = async (code: string) => {
  const GOOGLE_TOKEN_URL = `authentication/code-to-token`;
  const result = await postData({ uri: GOOGLE_TOKEN_URL, body: { code } });
  return result;
};

export const handleGoogleLogin = async (code: string) => {
  const GOOGLE_LOGIN = "authentication/google-login";
  const loginResult = await postData({
    uri: GOOGLE_LOGIN,
    body: {
      code,
    },
    convertToCamel: true,
  });
  return loginResult;
};

export const getGoogleTokens = async (userId: string) => {
  const GOOGLE_TOKEN_URL = `authentication/google-tokens`;
  const result = await postData({
    uri: GOOGLE_TOKEN_URL,
    body: { userId },
    convertToCamel: true,
  });
  const { accessToken } = result as { accessToken: string | null };
  if (accessToken === null) {
    console.error("No access token retrieved. Discontinuing process");
    return;
  }
  return accessToken;
};

export const googleLogin = async (tokenId: string) => {
  const GOOGLE_TOKEN_URL = `authentication/google-login`;
  const result = await postData({ uri: GOOGLE_TOKEN_URL, body: { tokenId } });
  return result;
};
