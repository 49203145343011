import useWindowSize from "hooks/useWindowSize";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useFramedModality } from "pages/viewer/hooks/useFramedModality";
import React, {
  useState,
  ReactNode,
  useMemo,
  useCallback,
  useEffect,
} from "react";

interface ScrubBarProps {
  children: ReactNode;
}
export const ScrubBar = ({ children }: ScrubBarProps) => {
  const {
    showScrub,
    setScrub,
    thumbTop,
    draggedThumbOffset,
    setDraggedThumbOffset,
    setIsScrolling,
  } = useThumbControls();

  const handleThumbMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    const startY = e.clientY;
    setIsScrolling(true);
    const onMouseMove = (e: MouseEvent) => {
      const deltaY = e.clientY - startY;
      setDraggedThumbOffset(deltaY);
    };

    const onMouseUp = (e: MouseEvent) => {
      const deltaY = e.clientY - startY;
      setScrub(deltaY);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  return (
    <div className="dicom-viewport-container">
      {children}

      {showScrub && (
        <div className="dicom-scrub-bar-container">
          <div
            className="scrub-thumb"
            onMouseDown={handleThumbMouseDown}
            style={{
              top: `${thumbTop + draggedThumbOffset}px`,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

const useThumbControls = () => {
  const {
    dispatch,
    state: { studyImageData, viewports, isMPRActive },
  } = useViewerContext();
  const { isFramedModality, totalFrames } = useFramedModality();
  const showScrub = useMemo(() => !isMPRActive, [isMPRActive]);
  const { height } = useWindowSize();
  const containerHeight = useMemo(() => height - 48, [height]);
  const currentViewport = useMemo(() => viewports[0], [viewports]);

  const [draggedThumbOffset, setDraggedThumbOffset] = useState<number>(0);

  const [thumbTop, setThumbTop] = useState<number>(0);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  useEffect(() => {
    if (!isScrolling && !isFramedModality && currentViewport) {
      const scrollImageIndex = Math.max(
        Math.min(
          Math.round(
            ((thumbTop + draggedThumbOffset) / containerHeight) *
              studyImageData.length
          ),
          studyImageData.length
        ),
        0
      );
      if (scrollImageIndex !== currentViewport.studyImageIndex) {
        const newThumbTop =
          (currentViewport.studyImageIndex / studyImageData.length) *
          containerHeight;
        setThumbTop(newThumbTop);
      }
    }
  }, [
    thumbTop,
    setThumbTop,
    draggedThumbOffset,
    isScrolling,
    currentViewport,
    studyImageData,
  ]);

  useEffect(() => {
    if (currentViewport && isScrolling) {
      if (isFramedModality) {
        const frameIndex = Math.max(
          Math.min(
            Math.round(
              ((thumbTop + draggedThumbOffset) / containerHeight) * totalFrames
            ),
            totalFrames
          ),
          0
        );
        dispatch({
          type: "SET_IMAGE_FRAME_INDEX",
          payload: { viewportIndex: 0, frameIndex },
        });
      } else {
        const imageIndex = Math.max(
          Math.min(
            Math.round(
              ((thumbTop + draggedThumbOffset) / containerHeight) *
                studyImageData.length
            ),
            studyImageData.length
          ),
          0
        );
        if (imageIndex !== currentViewport.studyImageIndex) {
          const updatedViewport = {
            ...currentViewport,
            imageIds: studyImageData[imageIndex].image,
            studyImageIndex: imageIndex,
          };
          dispatch({
            type: "CHANGE_ACTIVE_VIEWPORT",
            payload: { newViewport: updatedViewport },
          });
        }
      }
    }
  }, [
    isFramedModality,
    draggedThumbOffset,
    setDraggedThumbOffset,
    currentViewport,
    dispatch,
    containerHeight,
    isScrolling,
  ]);

  const setScrub = useCallback(
    (deltaY: number) => {
      setThumbTop(thumbTop + deltaY);
      setDraggedThumbOffset(0);
      setIsScrolling(false);
    },
    [
      setThumbTop,
      setDraggedThumbOffset,
      containerHeight,
      thumbTop,
      studyImageData,
      setIsScrolling,
    ]
  );

  return {
    thumbTop,
    setScrub,
    showScrub,
    draggedThumbOffset,
    setIsScrolling,
    setDraggedThumbOffset,
  };
};
