import cornerstone from "cornerstone-core";
import { useEffect, useState } from "react";
import { deselectTool, selectToolAndDeselectDefault } from "./useActiveTools";
import cornerstoneTools from "cornerstone-tools";
import { TOOL_IDS, WWWC_TOOL_CONFIG } from "../consts/tools.consts";

export const useIsMouseWheelDown = () => {
  const [isMouseWheelDown, setIsMouseWheelDown] = useState<boolean>(false);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.button === 1) {
        setIsMouseWheelDown(true);
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      if (event.button === 1) {
        setIsMouseWheelDown(false);
      }
    };

    const handleMouseLeave = () => {
      setIsMouseWheelDown(false);
    };

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const enabledElementsArray = cornerstone.getEnabledElements();
    enabledElementsArray.forEach(({ element }) => {
      if (isMouseWheelDown) {
        cornerstoneTools.setToolActiveForElement(element, TOOL_IDS.WWWC, {
          mouseButtonMask: 1,
        });
      } else {
        deselectTool(TOOL_IDS.WWWC, [WWWC_TOOL_CONFIG], element);
      }
    });
  }, [isMouseWheelDown]);
};
