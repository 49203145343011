import { getRowIndex } from "pages/viewer/helpers/gridDimensions";

export const calculateViewportStyles = (
  gridDimensions: number[],
  viewportIndex: number,
  parsedStyles?
) => {
  if (parsedStyles) {
    return parsedStyles;
  }
  const rowIndex = getRowIndex(gridDimensions, viewportIndex);

  return {
    backgroundColor: "black",
    width: `calc(${100 / gridDimensions[0]}vw)`,
    position: "absolute",
    height: `calc(${100 / gridDimensions[1]}vh - ${48 / gridDimensions[1]}px)`,
    flex: "1",
    top: `calc(${rowIndex} * ${100 / gridDimensions[1]}vh + ${
      48 / gridDimensions[1]
    }px)`,
    left: `calc(${viewportIndex} * ${100 / gridDimensions[0]}vw )`,

    ...parsedStyles,
  };
};
