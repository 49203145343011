import { useAppTheme } from "theme/useAppTheme";
import { Paper } from "@mui/material";

interface ImageBarItemProps {
  id: number;
  imageUrls: string[];
  imageIndex: number;
  setImageActive: (index: number) => void;
  onImageDrag: (clientX: number, clientY: number, index: number) => void;
  isActive: boolean;
}
export const ImageBarItem = ({
  id,
  imageUrls,
  imageIndex,
  setImageActive,
  onImageDrag,
  isActive,
}: ImageBarItemProps) => {
  const theme = useAppTheme();
  return (
    <div
      key={id}
      style={{
        position: "relative",
        cursor: "pointer",
        marginBottom: "10px",
        border: isActive
          ? `5px solid ${theme.palette.primary[700]}`
          : "5px solid white",
      }}
    >
      <img className="image-bar-item" src={imageUrls[0]} />
      <Paper
        draggable
        elevation={3}
        onDragEnd={(event) =>
          onImageDrag(event.clientX, event.clientY, imageIndex)
        }
        onClick={() => setImageActive(imageIndex)}
        sx={{
          position: "absolute",
          top: 0,
          width: "100px",
          height: "100px",
          bgcolor: "rgba(255, 0, 0, 0)",
          userSelect: "none",
        }}
      />
    </div>
  );
};
